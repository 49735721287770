import React, { useState, useMemo, createContext, useCallback } from 'react';

// create context
export const SidebarContext = createContext(undefined);

export const PopUpProvider = ({ children }) => {
  const [cartDrawerOpen, setCartDrawerOpen] = useState(false);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  const toggleCartDrawer = useCallback(() => setCartDrawerOpen(prev => !prev), []);
  const closeCartDrawer = useCallback(() => setCartDrawerOpen(false), []);

  const toggleMobileDrawer = useCallback(() => setMobileDrawerOpen(prev => !prev), []);
  const closeMobileDrawer = useCallback(() => setMobileDrawerOpen(false), []);

  const value = useMemo(
    () => ({
      cartDrawerOpen,
      toggleCartDrawer,
      closeCartDrawer,
      mobileDrawerOpen,
      toggleMobileDrawer,
      closeMobileDrawer,
    }),
    [
      cartDrawerOpen,
      toggleCartDrawer,
      closeCartDrawer,
      mobileDrawerOpen,
      toggleMobileDrawer,
      closeMobileDrawer
    ]
  );

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
};
